.main-separator {
  width: 100%;
  position: relative;
  height: 1rem;
  justify-content: center;
  margin-top: calc(1rem + 8vw);
  margin-bottom: calc(0.5rem + 4vw);
}

.main-separator-text {

  margin: 0 1rem;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  white-space: nowrap;
}

.main-separator-pattern {
  height: 1rem;
}

@media only screen and (min-width: 601px) {

  .main-separator {
    height: 1.5rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .main-separator-pattern {
    height: 1.5rem;
  }
}