.lightbox-container {

	display: table;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1000;
	background-color: #5555;
}

.lightbox-container.opaque {

	background: linear-gradient(to bottom, #0b0c1b, #3f3f4c 200vh);
}

.lightbox-container .lightbox-inner {

	display: table-cell;
	vertical-align: middle;
	text-align: center;
}

.lightbox-container .lightbox-inner .lightbox {

	position: relative;
	display: inline-block;
	background-color: #101010;
	max-width: 100%;
	max-height: 100%;
	margin: auto;
	padding: 1em;
	border-radius: 2px;
	box-shadow: 0px 0px 16px 0px rgba(100, 100, 100, 0.35);
	text-align: left;
	max-height: 80vh;
	max-width: 80vw;
	overflow: auto;
}

@media only screen and (max-width: 600px) {
  
  .lightbox-container .lightbox-inner .lightbox {

    height: 80vh;
    width: 80vw;
    background-color: #101010;
  }
  
  .small.lightbox-container .lightbox-inner .lightbox {

    height: unset;
    max-height: 80vh;
    width: 80vw;
    overflow: visible;
    background-color: #1f1f23
  }
}