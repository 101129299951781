.fadein {
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  margin: auto;
}

.fadein.left {
  transform: translateX(-1rem);
}

.fadein.right {
  transform: translateX(1rem);
  order: -1;
}

.fadein.center {
  transform: scale(0.9);
}

.fadein.center .rule-section {
  width: 100%;
}

@media only screen and (min-width: 601px) {

  .fadein.right {
    order: unset;
  }
}