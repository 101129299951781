.app {
  background-color: black;
  min-height: 100vh;
  font-size: calc(8px + 1.5vmin);
  letter-spacing: .05rem;
  color: #A5A4A9;
  font-family: DroidSerif;
  line-height: 1.5em;
}

.app * {
  display: flex;
  box-sizing: border-box;
}

.app span, .app strong {
  display: inline;
}

.invisible {
  display: none !important;
}

.app-background {
  z-index: 0;
  position: fixed;
  height: max(120vw, 200vh);
  width: max(120vw, 200vh);
  animation: background-rotate 280s linear infinite;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@keyframes background-rotate {
  from { transform: translate(-50%, -50%) rotateZ(0); }
  to { transform: translate(-50%, -50%) rotateZ(360deg); }
}

.app-footer {
  position: fixed;
  left: 0;
  bottom: -1px;
  width: 100vw;
  height: 2.5rem;
  background: linear-gradient(transparent, black);
  z-index: 2;
  opacity: 0.8;
  pointer-events: none;
}

.footer-left {
  height: 2.5rem;
  width: 8rem;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-right {
  height: 2.5rem;
  width: 8rem;
  position: absolute;
  top: 0;
  right: 0;
}

.app-footer .socials {
  margin: 0;
}

.app-footer .socials-list {
  margin: 0;
}

.app-footer .socials-list .social-link {
  margin: 0 0.15rem;
  height: 1.75rem;
  width: 1.75rem;
}

.app-footer .socials-list a {
  height: 1.75rem;
  width: 1.75rem;
  pointer-events: all;
}

.app-footer .socials-list a img {
    top: 0.125rem;
    left: 0.125rem;
    height: 1.5rem;
    width: 1.5rem;
}

.app-footer .socials-list a:hover img {
    top: 0rem;
    left: 0rem;
    height: 1.75rem;
    width: 1.75rem;
}

.app-footer .languages {
  height: 1.5rem;
  justify-content: center;
  width: 100%;
}

.language {
  transition: filter 0.15s ease-in-out;
  filter: grayscale(80%);
  cursor: pointer;
  pointer-events: all;
  position: relative;
  width: 50%;
  height: 100%;
}

.language img {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

.language.selected, .language:hover {
  filter: grayscale(0%);
  cursor: default;
}

@media (min-width: 601px) {

  .app-footer {
    height: 3rem;
    opacity: 0.6;
    transition: opacity 0.15s ease-in-out;
    pointer-events: all;
  }

  .app-footer:hover {
    opacity: 1;
  }

  .footer-left {
    height: 3rem;
    width: 8rem;
    position: absolute;
    top: 0;
    left: 0;
  }

  .footer-right {
    height: 3rem;
    width: 10rem;
    position: absolute;
    top: 0;
    right: 0;
  }

  .app-footer .socials-list .social-link {
    margin: 0 0.05rem;
    height: 2.5rem;
    width: 2.5rem;
  }

  .app-footer .socials-list a {
    height: 2.5rem;
    width: 2.5rem;
    pointer-events: all;
  }

  .app-footer .socials-list a img {
      top: 0.25rem;
      left: 0.25rem;
      height: 2rem;
      width: 2rem;
  }

  .app-footer .socials-list a:hover img {
      top: 0rem;
      left: 0rem;
      height: 2.5rem;
      width: 2.5rem;
  }

  .app-footer .languages {
    margin-top: 0.25rem;
    height: 2rem;
    justify-content: center;
    width: 100%;
  }

  .language {
    transition: filter 0.05s ease-in-out;
    filter: grayscale(80%);
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #00000020;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #00000050;
    /*background-color: #706555;*/
    border-radius: 5px;
    transition: background-color 0.2s;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #00000080;
    /*background-color: #575045;*/
  }

  ::-webkit-scrollbar-corner {
    background: #00000020;
  }

  .lightbox-inner ::-webkit-scrollbar-track {

    background: #FFFFFF20;
  }

  .lightbox-inner ::-webkit-scrollbar-thumb {

    background-color: #FFFFFF50;
  }

  .lightbox-inner ::-webkit-scrollbar-thumb:hover {
    
    background-color: #FFFFFF80;
  }

  .lightbox-inner ::-webkit-scrollbar-corner {

    background: #FFFFFF20;
  }
}