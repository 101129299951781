.home {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 10vw;
  width: 100vw;
  overflow: auto;
  z-index: 1;
  position: relative;
}

.home-title {
  position: relative;
  margin-bottom: 3rem;
}

.home-title img {
  height: 100%;
  width: 100%;
}

.home-subtitle {
  position: absolute;
  width: 100%;
  bottom: -1rem;
  left: 0;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: DroidSerif;
  font-weight: bold;
  letter-spacing: .25rem;
}

.catch-phrase {
  font-weight: bold;
  margin: 0 1rem;
  justify-content: center;
  text-align: center;
}

.main-image {
  width: 100%;
  margin: 1rem 0;
}

.game-stats {
  width: 100%;
  justify-content: center;
}

.game-stat {
  width: 3rem;
  height: 4.5rem;
  flex-direction: column;
  margin: 0 4vw;
  box-sizing: content-box;
}

.game-stat-icon {
  width: 3rem;
  height: 3rem;
}

.game-stat-text {
  margin-top: 0.5rem;
  margin-left: -1rem;
  width: 5rem;
  height: 1rem;
  line-height: 1rem;
  color: white;
  font-weight: bold;
  justify-content: center;
  text-align: center;
}

#game-stat-difficulty .game-stat-text {
  font-size: 0.8em;
}

.main-quote {
  position: relative;
  height: 6.5rem;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem;
  margin: 3rem 0;
}

.main-quote-line {
  text-align: center;
  justify-content: center;
  white-space: nowrap;
  font-style: italic;
}

.main-quote-background {
  width: 110%;
  max-width: 28rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.rule-section {
  flex-direction: column;
  margin-top: 5rem;
}

.main-separator + .rule-section {
  margin-top: 3rem;
}

.rule-section .left-section, .rule-section .bottom-section {
  flex-direction: column;
}

.rule-section .right-section {
  order: -1;
}

.rule-section .center-section {
  justify-content: center;
}

.rule-text {
  margin: 0.7rem 0;
  text-align: center;
  justify-content: center;
}

.rule-text span {
  font-size: 0.85rem;
  line-height: 1.275rem;
}

.rule-image {
  max-height: 12rem;
  max-width: 100%;
  margin: auto;
}

.rolling-section {
  width: 100%;
  position: relative;
  height: 6rem;
  overflow: hidden;
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
  -webkit-mask-image: -webkit-gradient(linear, left top, right top, 
    from(rgba(0,0,0,0)), color-stop(0.15, rgba(0,0,0,1)), color-stop(0.85, rgba(0,0,0,1)), to(rgba(0,0,0,0)));
}

.rolling-section .rule-image {
  position: absolute;
  max-width: unset;
  max-height: 142px;
  height: 80%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  animation: rolling-section-translate linear infinite 100s;
  -webkit-animation: rolling-section-translate linear infinite 100s;
}

.rolling-section .rule-image + .rule-image {
  animation-delay: -50s;
  -webkit-animation-delay: -50s;
}

.rolling-section.backwards .rule-image {
  animation-direction: reverse;
  -webkit-animation-direction: reverse;
}

.rolling-section::before, .rolling-section::after {
  position: absolute;
  width: 2px;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(transparent, white, transparent);
  z-index: 1;
}

.rolling-section::after {
  left: unset;
  right: 0;
}

@keyframes rolling-section-translate {
  from { transform: translateY(-50%) translateX(100%); }
  to { transform: translateY(-50%) translateX(-100%); }
}

.lower-title {
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lower-title img {
  width: 70%;
  margin: auto;
}

.lower-subtitle {
  position: absolute;
  width: 100%;
  bottom: -1.5rem;
  left: 0;
  text-align: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: DroidSerif;
  font-weight: bold;
  letter-spacing: .25rem;
}

.socials {
  flex-direction: column;
  margin-top: 1.5rem;
  width: 100%;
}

.socials-text {
  font-weight: bold;
  justify-content: center;
  color: white;
  letter-spacing: .1rem;
}

.socials-list {
  margin-top: 1rem;
  justify-content: center;
}

.socials-list .social-link {

  margin: 0 0.5rem;
  height: 4.5rem;
  width: 4.5rem;
}

.socials-list a {
  position: relative;
  height: 4.5rem;
  width: 4.5rem;
}

.socials-list a img {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  height: 4rem;
  width: 4rem;
  transition: all 0.15s ease-in-out;
}

.socials-list a:hover img {
  top: 0;
  left: 0;
  height: 4.5rem;
  width: 4.5rem;
}

.event-list {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.event {
  height: 4rem;
  width: 4rem;
  margin: 0.25rem;
}

.news {
  width: 100%;
}

.news-list {
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
}

.news-link {
  width: 30%;
  aspect-ratio: 1 / 1;
  margin: 1%;
  position: relative;
  overflow: hidden;
}

.news-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: radial-gradient(#ffffff80, white);
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}

.news-link:hover::after {
  opacity: 0.3;
}

.news-link a, .news-link img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.legal-documents {
  margin-top: 4rem;
  text-transform: uppercase;
  font-weight: bold;
  justify-content: center;
  width: 100%;
  font-size: 0.85em;
}

.legal-document {
  transition: color 0.15s ease-in-out;
  cursor: pointer;
}

.legal-document:hover {
  color: white;
}

.legal-info {
  margin-top: 1rem;
  font-size: 0.72em;
  letter-spacing: .025rem;
  justify-content: center;
  text-align: center;
}

.legal-document-details h2 {
  text-align: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.document-subsection-title {
  color: white;
  margin: 0.5rem;
}

.document-subsection-paragraph {
}

.legal-document-details h2 {
  text-align: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 601px) {

  .home {
    max-width: calc(30rem + 30vw);
    margin: auto;
    font-size: 0.85em;
  }

  .home-title {
    margin-bottom: 5rem;
  }

  .catch-phrase {
    max-width: 29rem;
  }

  .main-image {
    margin: 2.5rem 0;
  }

  .main-quote {
    height: 8rem;
    margin: 5rem 0;
    margin-bottom: 3rem;
  }

  .game-stat {
    margin: 0 1.5rem;
  }

  .rule-section {
    font-size: 0.85rem;
    line-height: 1.275rem;
  }

  .rule-section.row {
    flex-direction: row;
  }

  .rule-section.row .fadein, .rule-section.row .left-section, .rule-section.row .right-section {
    width: 50%;
  }

  .rule-section.row .fadein .left-section, .rule-section.row .fadein .right-section {
    width: 100%;
  }

  .rule-section .right-section {
    order: unset;
  }

  .rule-section .bottom-section {
    margin-top: 1rem;
  }

  .lower-title {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .legal-documents {
    margin-top: 5rem;
  }
}